import alipay from 'payment-icons/min/flat/alipay.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import defaultCard from 'payment-icons/min/flat/default.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import elo from 'payment-icons/min/flat/elo.svg';
import hiperCard from 'payment-icons/min/flat/hipercard.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import maestroOld from 'payment-icons/min/flat/maestro-old.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import mastercardOld from 'payment-icons/min/flat/mastercard-old.svg';
import paypal from 'payment-icons/min/flat/paypal.svg';
import securityCode from 'payment-icons/min/flat/security-code.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import verve from 'payment-icons/min/flat/verve.svg';
import visa from 'payment-icons/min/flat/visa.svg';

export const CARDS = {
  visa: {
    icon: visa,
    name: "Visa"
  },
  mastercard: {
    icon: mastercard,
    name: "Mastercard"
  },
  amex: {
    icon: amex,
    name: "American Express"
  },
  discover: {
    icon: discover,
    name: "Discover"
  },
  diners: {
    icon: diners,
    name: "Diners Club"
  },
  jcb: {
    icon: jcb,
    name: "JCB"
  },
  unionpay: {
    icon: unionpay,
    name: "UnionPay"
  },
  unknown: {
    icon: defaultCard,
    name: "Unknown"
  }
}
